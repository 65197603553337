<div>
  <app-screen-title [title]="'SETTINGS.frequent_questions_1'" [color]="'white'"
    [backTo]="'/settings'"></app-screen-title>
  <div class=" flc">
    <img style="width: 160px;" src="assets/images/questions.png" alt="My Image">
  </div>

  <div id="faq">
    <div class="card card-main  fixed-bottom cu-card p-4">
      <p-accordion [activeIndex]="0">
        <p-accordionTab header="{{ 'FAQ.q1' |translate}}">
          <p class="mt-2 op-70">
            {{ 'FAQ.resp1' |translate}}
          </p>
        </p-accordionTab>
        <hr class="p-0">
        <p-accordionTab header="{{'FAQ.q2' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp2' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q3' |translate}}">
          <p class="mt-2 op-70">
            {{ 'FAQ.resp3' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q4' |translate}}">
          <p  class=" mt-2 op-70">
            {{ 'FAQ.resp4' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q5' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp5' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q6' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp6' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q7' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp7' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q8' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp8' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q9' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp9' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q10' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp10' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q11' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp11' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q12' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp12' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q13' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp13' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q14' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp14' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q15' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp15' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
        <p-accordionTab header="{{'FAQ.q16' |translate}}">
          <p  class="mt-2 op-70">
            {{ 'FAQ.resp16' |translate}}
          </p>
        </p-accordionTab>
        <hr style="padding:unset !important">
      </p-accordion>
      <!-- <label class="text-center label-info" style="margin-bottom: unset !important;"> Beta-Version </label> -->
    </div>
  </div>


</div>
