<app-screen-title [title]="'TITLE.existing_financing'" [color]="'white'" [backTo]="'/loan-applications'"></app-screen-title>
<div class="vw-100 px-3">
  <div id="schedule-details" >

    <!-- Curved Progress Bar Percentage -->
    <div class="flc mb-4">
      <div class="cpb" role="progressbar" [attr.aria-valuenow]="getPercentage()" aria-valuemin="0" aria-valuemax="100"
        [style.--value]="getPercentage()">
        <div class="grid">
          <label class="fw-700 white text-center mb-0" [ngClass]="{'fsm' : lang == 'en','fsl' : lang =='ar'}" style="margin-bottom:unset">{{ 'LABELS.payment_status' |
            translate }}</label>
          <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus === 100"> {{ loan.percentagePayment / 100 | percent:'1.0-0' }} </span>
          <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus != 100"> {{paymentStatus | percent:'1.2-2'}}</span>
          <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus == undefined"> 0%</span>
        </div>
      </div>
    </div>

    <!-- Sticky Card (2) -->
    <div class="row" >
      <div class="col-6">
        <div class="card sticky-card flc p-2">
          <span class="fw-600 text-center">
            <img class="ib-icons "  src="assets/images/icons/money_icon.png" alt="money_case_icon">
               {{ 'LABELS.amount_term' | translate }}
          </span>
          <label class="flc fw-600 color1 fsl" > {{loan.normalPayment}} {{'CURRENCY.sar' | translate }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="card sticky-card flc p-2">
          <span class="fw-600 text-center ">
            <img class="ib-icons"  src="assets/images/icons/money_round.png" alt="money_round">
            {{'LABELS.financing_amount' | translate }}
          </span>
          <label class="flc fw-600 color1 fsl" > {{loan.approvelAmount}}  {{'CURRENCY.sar' | translate }}</label>
        </div>
      </div>
    </div>

    <!-- Schedules Details Card  -->
    <div class="card card-main  fixed-bottom pt-4">
      <div class="card-body schedule-card-sticky" style="overflow: unset">
        <!-- Documents & Services Buttons -->
        <div class="row flc pt-2">
          <div class="col-6">
            <button type="button" class="btn btn-secondary cu-btn px-0" style="border-radius: 16px !important;" routerLink="/documents">
                <img class="ib-icons m-1"  src="assets/images/icons/sheets.png" alt="doc_verified">
                <label class="m-1 fw-500 white ">{{ 'BUTTONS.documents' | translate }}</label>
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-secondary cu-btn px-0" style="border-radius: 16px !important;" routerLink="/schedule-services">
                <img class="ib-icons m-1"  src="assets/images/icons/doc_verified.png" alt="doc_verified">
                <label class="m-1 fw-500 white ">{{ 'BUTTONS.services' | translate }}</label>
            </button>
          </div>
        </div>
        <!-- Schedule Info  ==> ACCESS TO ALL SCHEDULES PAYMENTS -->
        <div id="schedule-info" class="mb-5 h-100" style="overflow-y:auto;">
          <div class="row mt-2 px-2">
            <div class="col-5 block text-center mt-4">
              <label class="mb-1 color1 fw-650"> {{ 'LABELS.next-repayment' | translate }}</label>
              <label class="mb-1 fw-650 d-block" *ngIf="!loan.totalRepayment && loan.normalPayment"> {{loan.normalPayment}} {{ 'CURRENCY.sar' | translate }}</label>
              <label class="mb-1 fw-650 d-block" *ngIf="loan.totalRepayment"> {{loan.totalRepayment}} {{ 'CURRENCY.sar' | translate }}</label>

              <label class="fss op-50" >   {{nextRepaymentDate}}      </label>
            </div>
            <div class="col-2 flc">
              <hr>
            </div>
            <div class="col-5 block text-center mt-4">
              <label class="mb-1 color1 fw-650"> {{ 'LABELS.last_due_date' | translate
                }}</label>
              <label class="mb-1 fw-650 d-block" *ngIf="loan.normalPayment">{{loan.normalPayment}} {{ 'CURRENCY.sar' | translate }}</label>
              <label class="op-50 fw-500 fss"> {{lastRepaymentDate}} </label>
            </div>
          </div>
          <div class="flc py-2 mb-4">
            <button type="button" class="btn btn-secondary px-4 pb-2" routerLink="/schedule-payment" [disabled]="schedules.length == 0 || schedules == undefined || schedules === null">
              <div class="fsm">
                <img class="ib-icons m-1"  src="assets/images/icons/calendar-icon.png" alt="calendar-icon">
               {{ 'BUTTONS.payments' | translate }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Nex Repayment Div Card Fixed Bottom -->
<div class="fixed-bottom " >
  <div class="card p-3" style="filter: drop-shadow(2px 4px 6px black);border-radius: 0;">
    <div class="fsb px-3">
      <label class="mb-1 fw-700 color1 fsm"> {{'LABELS.next-repayment' |translate}}</label>
      <label class="mb-1 fw-600 color1 fsm" *ngIf="loan.normalPayment"> {{loan.normalPayment}} {{ 'CURRENCY.sar' | translate }}</label>
    </div>
    <div class="text-center p-2">
      <button type="button" class="btn btn-primary btn-lg  login-btn" routerLink="/new-transaction" >{{'BUTTONS.pay_now' | translate}}</button>
    </div>
  </div>
</div>

