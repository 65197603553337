import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-screen-title',
  templateUrl: './screen-title.component.html',
  styleUrls: ['./screen-title.component.scss']
})
export class ScreenTitleComponent implements OnInit {

  @Input() title: string | undefined;
  @Input() backTo : string | undefined;
  @Input() color : string | undefined;

  lang : string;

  public customStyle : any;
  public backgroundStyle : any;

  color1={'color':'#213592'};
  color2={'color':'#314fd4'};
  white={'color':'white'};
  bgColor1={'background-color':'#E9EDF8'}
  bgColor2={'background-color':'#30449E'}
  constructor(private router: Router,private translate : TranslateService, private authService : AuthentificationService) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    switch(this.color){
      case 'color1':
        this.customStyle = this.color1;
        this.backgroundStyle = this.bgColor1;
        break;
      case 'color2':
        this.customStyle = this.color2;
        break;
      case 'white':
        this.customStyle = this.white;
        this.backgroundStyle = this.bgColor2;
    }

    if (this.backTo === undefined) this.backTo = '/' + PathConstants.PATH_HOME;
  }

  goBack(){
    if (location.pathname == '/otp-biometrics'){
      this.authService.logout();
    }else {
      this.router.navigate([this.backTo]);
    }
  }


}
