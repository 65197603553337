<!-- Simah Launch Process-->
<div  class="bg-2 vh-100">
  <app-screen-title [title]="'TITLE.simah_consent'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>

  <div id="simah_launch_process" style="height: 80vh;flex-direction: column;" class="pt-2 mt-4 fsa"
    *ngIf="showSimahCheck">
    <div class="p-2 flc">
      <img src="./assets/images/simah.png" class="p-4" style="width:250px;">
    </div>
    <div>
      <div class="m-3 d-flex">
        <input type="checkbox" id="terms-conditions" class="m-1" [(ngModel)]="isCheckedSimah">
        <label for="myCheckbox" class="label-main fw-650 fss">
          {{'TERMS.agree_on_simah' | translate}}
        </label>
      </div>
      <div class="text-center py-5 pt-2 px-4">
        <button type="button" class="btn btn-primary btn-lg mb-4 login-btn" (click)="confirmFollowUp()"
          [disabled]="!isCheckedSimah">
          {{'BUTTONS.confirm_followUp' | translate}}
        </button>
      </div>
    </div>
  </div>


  <!-- Simah Check -->
  <div id="simah_check" class="pt-2" *ngIf="showSimahVerificationFromACM && !showSimahCheck">

    <!-- <label class="label-info text-center mt-2">{{'OTHER.simah_launched' | translate}}</label> -->

    <div class="payment-no-refresh py-2">
      <div class="payment-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h2>{{'INFO.waiting_for_response' |translate}}</h2>
    </div>

    <div class="d-grid p-2">
      <img src="./assets/images/simah.png" class="p-4 text-center" style="width:250px;">
    </div>



    <div class="text-center py-3 fixed-bottom px-2">
      <button type="button" class="btn btn-primary btn-lg login-btn" routerLink="/home">
        {{'BUTTONS.check_later' | translate}}
      </button>
    </div>
  </div>
</div>
<app-popup *ngIf="showCheckAmlPopup" [source]="'AML'"></app-popup>
<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined"></app-footer-menu>
