<div style="display: contents !important;">
  <div id="main-authentication">
    <div class="mt-4 text-center cp-lr-10">
      <div class="ms-2  mb-4 flc inline-flex align-items-center">
        <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="My Image">
      </div>
    </div>
  </div>
</div>

<!-- LOGIN && SIGNUP TABS -->
<div class="px-3 pb-2">
  <div class="card tab-card">
    <div class="btn-group flex cp-3">
      <button type="button" class="btn tab-btn" [ngClass]="{'tab-btn-active': showLogin}" (click)="actLogin()">
        {{'BUTTONS.login' | translate}}
      </button>
      <button type="button" class="btn tab-btn" [ngClass]="{'tab-btn-active': showSignUp}" (click)="actSignup()">

        {{'BUTTONS.sign_up' | translate}}
      </button>
    </div>
  </div>
</div>

<!-- LOGIN -->
<div id="login" class="card card-main fixed-bottom" *ngIf="!showSignUp && showLogin">
  <div class="card-header card-header-main text-center color7">
    {{'CARD_HEADERS.header_signin' | translate}}
  </div>
  <div class="card-body card-fb-body-login p-3">
    <div id="login-auth-normal">
      <form [formGroup]="loginForm">
        <div class="mb-2">
          <label class="form-label ms-2">{{'LABELS.national_id' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
              style="position: absolute;">
            <input type="text" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}"
              maxlength="10"
              [ngClass]="{'fc-error': submittedLogin && loginForm.controls['username'].invalid  || passwordError}"
              formControlName="username" (input)="letterRemover()">
          </div>
        </div>

        <div class="mb-1">
          <label class="form-label ms-2">{{'LABELS.password' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control icon-input"
              [ngClass]="{'fc-error': submittedLogin && loginForm.controls['password'].invalid || passwordError}"
              placeholder="{{'PLACEHOLDER.enter_pwd' | translate}}" formControlName="password">
          </div>
          <button class="btn" type="button" (click)="togglePasswordVisibility()"
            [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -40px;">
            <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
          </button>
        </div>
      </form>
      <div>
        <div class="fsb col-11">
          <div>
            <input type="checkbox" id="terms-conditions" class="m-1">
            <label for="myCheckbox" class="label-main fw-650 fss m-2">
              {{'LABELS.remember_me' | translate}}
            </label>
          </div>
          <a routerLink="/forgetpassword" class="fw-650 fss m-1" style="padding-top: 5px;">{{'LABELS.forgot_password' |
            translate}}</a>
        </div>
      </div>

      <div class="text-center" *ngIf="biometricsStatus" (click)="launchBiometricsAuth()">
        <div class="m-1 inline-flex align-items-center">
          <img class="ib-icons" src="assets/images/biometrics.png" alt="biometrics">
        </div>
      </div>

      <div class="text-center pt-3">
        <button type="button" class="btn btn-primary btn-lg mb-1 login-btn" (click)="login()">
          {{'BUTTONS.login' | translate}}
        </button>
        <a routerLink="claims">{{'LABELS.add_new_claim' |translate}}</a>
      </div>
    </div>

  </div>
</div>

<!-- SIGN UP -->
<div id="sign_up" class="card card-main fixed-bottom" *ngIf="showSignUp && !showLogin">
  <div class="card-header card-header-main text-center fss color7">
    {{'CARD_HEADERS.header_signup' | translate}}
  </div>

  <div class="card-body card-fb-body-login p-3">
    <form [formGroup]="signupForm">
      <div class="mb-2">
        <label class="form-label ms-2 " for="first_name">{{ 'LABELS.first_name' | translate}} <span
            class="ms-1 red">*</span></label>
        <div style="position: relative;">
          <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_first_name' | translate}}"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['first_name'].invalid }"
            formControlName="first_name">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2" for="last_name">{{ 'LABELS.last_name' | translate}} <span
            class="ms-1 red">*</span></label>
        <div style="position: relative;">
          <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_last_name' | translate}}"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['last_name'].invalid}"
            formControlName="last_name">
        </div>
      </div>
      <div class="mb-2">
        <div class="fsb">
          <label class="form-label ms-2" for="mobileNumber">{{ 'LABELS.phone_number' | translate}} <span
              class="ms-1 red">*</span></label>
          <label class="form-label ms-2  text-secondary fss">{{'LABELS.phone_number_registered_in_absher' |
            translate}}</label>
        </div>
        <div class="" style="position: relative;">
          <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() != 'ar' ">+ 966</span>
          <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() == 'ar' ">966 +</span>
          <input type="number" class="form-control mobile-number-input" placeholder="5 XXXX XXXX"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['mobileNumber'].invalid}"
            aria-label="phone_number" formControlName="mobileNumber" maxlength="9"
            (input)="checklength($event,'mobileNumber')" dir="ltr">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2" for="nationalId">{{'LABELS.national_id' | translate}}<span
            class="m-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
            style="position: absolute;">
          <input type="number" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['nationalId'].invalid}"
            formControlName="nationalId" (input)="checklength($event,'nationalId')">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2" for="email">{{'LABELS.email_address' | translate}} <span
            class="m-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/mail.png" alt="mail" style="position: absolute;">
          <input type="email" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_email' | translate}}"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['email'].invalid}" formControlName="email">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label" for="password">{{ 'LABELS.password' | translate}} <span
            class="m-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
          <input type="password" [type]="showPassword ? 'text' : 'password'" class="form-control icon-input"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['password'].invalid }"
            placeholder="{{'PLACEHOLDER.enter_pwd' | translate}}" formControlName="password">
          <div class="px-2 mt-2" style="display: flex; align-items: center;gap: 5px;">
            <i class='bx bx-info-circle mt-1 color1'></i>
            <span style="font-size: 12px;color:black" class="op-70">{{ 'password_requirments' |translate}}</span>
          </div>
          <div class="password-strength-meter">
            <div class="strength-bar" [style.width.%]="passwordStrength"
              [ngClass]="{ 'bg-weak': passwordStrength < 40, 'bg-medium': passwordStrength >= 40 && passwordStrength < 70, 'bg-strong': passwordStrength >= 70 }">
            </div>
          </div>
          <div class="strength-text"
            [ngClass]="{ 'weak': passwordStrength < 40, 'medium': passwordStrength >= 40 && passwordStrength < 70, 'strong': passwordStrength >= 70 }">
            {{ passwordStrengthText | translate }}
          </div>
        </div>

        <button class="btn" type="button" (click)="togglePasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative;top:-115px">
          <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'"
            style="font-size: 20px !important;color: #707fc2;"></i>
        </button>

      </div>
      <div class="mb-2">
        <label class="form-label ms-2 " for="confirmPassword">{{ 'LABELS.confirm_password' | translate}} <span
            class="ms-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
          <input type="password" [type]="showConfirmPassword ? 'text' : 'password'" class="form-control icon-input"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['confirmPassword'].invalid}"
            placeholder="{{'PLACEHOLDER.confirm_pwd' | translate}}" formControlName="confirmPassword">
        </div>
        <button class="btn" type="button" (click)="toggleConfirmPasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -45px;">
          <i class="bx" [ngClass]="showConfirmPassword ? 'bx-hide' : 'bx-show'"
            style="font-size: 20px !important;color: #707fc2;"></i>
        </button>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 " for="dateOfBirth">{{ 'LABELS.date_of_birth' | translate}} <span
            class="ms-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon"
            style="position: absolute;">
          <input type="date" class="form-control text-center" formControlName="dateOfBirth" (change)="convertToHijri()"
            [ngClass]="{'fc-error': submittedSignup && signupForm.controls['dateOfBirth'].invalid }">
        </div>
      </div>

      <div class="mb-2">
        <label class="form-label ms-2 " for="dateOfBirth">{{ 'LABELS.date_of_birth_hijri' | translate}}<span
            class="ms-1 red">*</span></label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon"
            style="position: absolute;z-index: 1111;">
          <flx-localized-datepicker [maxDate$]="toDateWithStart$" (selectDate)="fromDate$.next($event)"
            (sendDateHijriEvent)="handleHijriDate($event)"></flx-localized-datepicker>
        </div>
      </div>
      <div class="mt-4 mb-4" style="display: flex;">
        <input type="checkbox" id="terms-conditions" class="m-1" formControlName="isCheckedTerms">
        <label for="myCheckbox" class="label-main fw-650 fss" for="isCheckedTerms" (click)="openModalTerms()">
          {{'INFO_terms_agreements' | translate}}
        </label>
      </div>
    </form>

    <div class="text-center">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="createAccount()">
        {{'BUTTONS.create_account' | translate}}
      </button>
    </div>
  </div>

</div>

<!-- Dialog Terms & Agreements-->
<div id="modal-dialog">
  <p-dialog [(visible)]="showModalTerms" [breakpoints]="{'80vh': '80vw'}" [style]="styleDialog" [closable]="false"
    [modal]="true" overlay="true">
    <div style="text-align: justify;">
      <h3 class="mb-4 mt-2 flc color1 fw-500">{{'INFO.terms_conditions' | translate}}</h3>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.first_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_lecture' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.second_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.definitions' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.third_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.needs_subscription' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fourth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_usecases' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fifth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_confidentiality' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.sixth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.disclaimer_compensation' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.seventh_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_changes' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.eighth_part' |translate}}
      </span>

      <legend class="fss fw-600 black"> {{'INFO.check_payment_society'| translate}}</legend>
      <a class="u fw-600 fss flc"> {{'LINK.rules_protection' | translate}}</a>

      <li><a class="u fw-600 fss">{{'TERMS.followed_law' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.nineth_part' |translate}}
      </span>

      <li><a class="u fw-600 fss">{{'TERMS.agent_acceptance' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.tenth_part' |translate}}
      </span>

    </div>
    <div class="row">
      <div class="flc p-3">
        <button (click)="confirmTerms()" class="btn-pill btn btn-sm btn-outline-primary cf-hover-btn color1"><i
            class='bx bx-check-square'></i> {{'BUTTONS.confirm' | translate}} </button>
      </div>
    </div>
  </p-dialog>
</div>
