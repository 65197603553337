import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IbNotificationServiceService } from 'src/app/ib-services/IbNotificationService.service';
import { WebsocketService } from 'src/app/ib-services/Websocket.service';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { ConfirmTransactionIcsEntity } from 'src/app/shared/Entities/requestConfirmTransaction.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public customer: CustomerEntity;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public showModalFirstLogin: Boolean = false;
  public loansList: LoanEntityIB[] = [];
  public user: UserEntity;
  public schedules: ScheduleEntity[] = [];
  public schedulesForLoan: ScheduleEntity[] = [];
  public schedulesLoaded = false;
  public scheduleSyncIcon: boolean = false;
  public maxAmountProduct: number;
  public product: ProductEntity;
  public listProds: ProductEntity[] = [];
  public lang: string;
  public nbNotifs: number;
  public showPopupEval: boolean = false;

  public clickableSteps: string[] = [
    'Simah consent',
    ' Customer Information & Documents',
    'Sign Sanad Elamr',
    'Sign Contract',
    'Customer decision',
  ];

  /** Dialog Style */
  public styleDialog = {
    width: '50vw',
    background: 'linear-gradient(to bottom, #75ccd6, #ebedf8)',
    padding: '10px 10px 10px 10px',
    borderRadius: '20px',
    color: 'white',
    filter: 'drop-shadow(2px 4px 6px black)',
  };

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private translate: TranslateService,
    private webSocketService: WebsocketService,
    private notifService: IbNotificationServiceService,
    private parametrageService: ParametrageService,
    private customerService: CustomerService,
    private loanService: LoanService,
    private thirdPartyService: ThirdPartyService,
    private authService: AuthentificationService
  ) {}

  ngOnInit() {
    /** Check Language Set */
    this.lang = localStorage.getItem('lang');
    if (!this.lang) {
      localStorage.setItem('lang', 'ar');
    }
    if (
      !this.sharedService.getLang() ||
      this.sharedService.getLang() === undefined
    ) {
      this.sharedService.setLang('ar');
    }
    let prod = new ProductEntity();
    this.parametrageService.getProducts(prod).subscribe((res) => {
      this.listProds = [...res];
      this.product = this.listProds[0];
      this.maxAmountProduct = this.product.productDetailsDTOs[0].maximumAmount;
    });

    this.user = this.sharedService.getUser();
    if (this.user.customerId) {
      this.customerService
        .getCustomerById(this.user.customerId)
        .subscribe((res) => {
          this.customer = res;
          this.sharedService.setCustomer(res);
          /*** Display Name From Customer (Next TO Be Done by Updating info from nafidh in user IB ) */
          let splittedCustomerName: string[] = [];
          splittedCustomerName = this.customer.customerName.split('|');
          this.customer.firstName = splittedCustomerName[0];
          this.customer.middleName = splittedCustomerName[2];
          this.customer.lastName = splittedCustomerName[3];

          /** Fetch Notification List */
          this.fetchNbNotifications();

          /** Launch WebSocket COunt Notification */
          this.webSocketService.advance(
            '/topic/count-notifications',
            (): any => {
              console.log(
                '************  ENTERED SUBSCRIBE WS COUNT NOTIFICATIONS API ************'
              );
              this.fetchNbNotifications();
            }
          );

          /** Launch WebSocket List Of Loans */
          this.webSocketService.advance('/topic/list-loans', (): any => {
            console.log(
              '************  ENTERED SUBSCRIBE WS LIST LOANS API ************'
            );
            this.refreshListLoans();
          });
        });
    }

    if (this.user.login === undefined) {
      this.router.navigate(['']);
    }

    /** Check Loans To Delete */
    let customer = new CustomerEntity();
    customer.ibCustomerId = this.user.customerId;
    let loan = new LoanEntityIB();
    loan.customerDTO = customer;
    this.loanService.getLoanByCustomerNID(loan).subscribe((loans) => {
      /** Filter out loans where insertionDate is older than 30 minutes */
      const currentDate = new Date();

      let loansToDelete: LoanEntityIB[] = loans.filter(
        (loan) => loan.accountNumber == null
      );
      loansToDelete.forEach((loan, index) => {
        let insertionDate = new Date(loan.dateInsertion);
        let timeDifference = currentDate.getTime() - insertionDate.getTime();
        let minutesDifference = timeDifference / (1000 * 60);
        if (minutesDifference > 20) {
          this.loanService.deleteLoanById(loan.idIbLoan).subscribe((resDel) => {
            this.sharedService.showInfo(
              this.translate.instant('INFO.loan_deleted')
            );
            this.refreshListLoans();
          });
        }
      });
      /** END Filter out loans where insertionDate is older than 30 minutes */
    });

    this.refreshListLoans();
  }

  fetchNbNotifications() {
    this.notifService
      .getNotificationsCount(this.customer.ibCustomerId)
      .subscribe((res) => {
        this.nbNotifs = res;
      });
  }

  refreshListLoans() {
    let customer = new CustomerEntity();
    customer.ibCustomerId = this.user.customerId;
    let loan = new LoanEntityIB();
    loan.customerDTO = customer;
    this.loanService.getLoanByCustomerNID(loan).subscribe(
      (loans) => {
        this.loansList = [...loans];
        this.getMaxAmount();
        let issuedLoan = this.loansList.find(
          (res) => res.statut === '8' //  => Issued Status
        );
        if (issuedLoan) this.scheduleSyncIcon = true;
        this.loansList.reverse();
      },
      (error) => {
        console.log('BAD REQUEST : ', error);
        this.sharedService.showError(this.translate.instant('ERR.otp.later'));
        this.authService.logout();
      }
    );
  }

  redirectLoan(index: number) {
    this.sharedService.setLoanDeal(this.loansList[index]);
    if (this.loansList[index].stepPath == PathConstants.PATH_PENDING) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'pending' },
      });
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_PERSONAL_INFORMATION
    ) {
      this.router.navigate(['/' + PathConstants.PATH_INFO_ENTRY]);
    } else if (this.loansList[index].statutLibelle == 'Disburse') {
      this.sharedService.showInfo(
        this.translate.instant('INFO.waiting_for_disburse')
      );
    } else if (this.loansList[index].statutLibelle == 'Get info from Dakhli') {
      this.sharedService.showInfo(
        this.translate.instant('INFO.request_sent_to_calculate')
      );
    } else if (this.loansList[index].statut == '8') {
      this.sharedService.showSuccess(
        this.translate.instant('INFO.loan_steps_completed')
      );
      this.showPopupEval = true;
      this.sharedService.setLoanForEval(this.loansList[index]);
    } else if (
      this.loansList[index].statutLibelle == 'Check Score' ||
      this.loansList[index].statutLibelle == 'Check AML'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'pending' },
      });
    } else if (
      this.loansList[index].statutLibelle.toLowerCase() == 'simah consent'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_CONSENT_VALID]);
    } else if (
      this.loansList[index].statutLibelle == 'Customer decision' &&
      this.sharedService.getShowFirstRequestApproved() === true
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'success' },
      });
    } else if (
      this.loansList[index].statut == '6' ||
      this.loansList[index].statut == '5'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'refused' },
      });
      this.sharedService.setLoanForEval(this.loansList[index]);
    } else if (
      !this.loansList[index].accountNumber ||
      this.loansList[index].accountNumber == null
    ) {
      this.router.navigate(['/' + PathConstants.PATH_NAFEDH], {
        queryParams: { status: 'launched_nafedh' },
      });
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_BANK_DETAILS
    ) {
      this.router.navigate(['/' + PathConstants.PATH_BANK_INFO]);
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_SIGN_CONTRACT &&
      this.loansList[index].statutLibelle == 'Call Customer'
    ) {
      let confirmTransactionIcsEntity = new ConfirmTransactionIcsEntity();
      confirmTransactionIcsEntity.idNumber =
        this.loansList[index].customerDTO.identity;
      confirmTransactionIcsEntity.phoneNumber = this.customer.telephone1;
      confirmTransactionIcsEntity.transactionType = 'financing';
      this.router.navigate(['/' + this.loansList[index].stepPath]);

      /** Endpoint Moved to ACM */
      // this.confirmTransactionIcsApi(
      //   confirmTransactionIcsEntity,
      //   this.loansList[index].idIbLoan
      // );
    } else {
      this.router.navigate(['/' + this.loansList[index].stepPath]);
    }
  }

  getMaxAmount() {
    this.loansList.forEach((loan) => {
      if (loan.approvelAmount) {
        this.maxAmountProduct = loan.approvelAmount;
      }
    });
  }

  confirmTransactionIcsApi(
    confirmTransactionIcsApi: ConfirmTransactionIcsEntity,
    lId: number
  ) {
    this.thirdPartyService
      .confirmTransactionIcsApi(confirmTransactionIcsApi, lId)
      .subscribe((res) => {});
  }

  getIfTranslationExist(statusLibelle: string): string {
    let translate: string = this.translate.instant(
      'STATUT_LIBELLE.' + statusLibelle
    );
    return translate.includes('STATUT_LIBELLE.') ? statusLibelle : translate;
  }
}
