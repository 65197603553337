import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-application-terms',
  templateUrl: './application-terms.component.html',
  styleUrls: ['./application-terms.component.scss'],
})
export class ApplicationTermsComponent implements OnInit {
  public listProds: ProductEntity[] = [];
  public product: ProductEntity;

  constructor(
    private parametrageService: ParametrageService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    let prod = new ProductEntity();
    this.parametrageService.getProducts(prod).subscribe((res) => {
      this.listProds = [...res];
      this.product = this.listProds[0];
    });
  }

  checkProductsBeforeRedirection() {
    if (this.listProds.length > 1) {
      this.router.navigate(['/' + PathConstants.PATH_PRODUCT_LIST]);
    } else {
      this.sharedService.setProduct(this.listProds[0]);
      this.router.navigate(['/' + PathConstants.PATH_NAFEDH]);
    }
  }
}
