<div class="bg-2 vw-100">

  <!-- My Applies Menu -->
  <div id="menu-applications" class="vh-100" *ngIf="showAppliesMenu && !showMyLoanStatuses">
    <app-screen-title [title]="'TITLE.my_requests'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>
    <div class="px-4 mt-4">
      <div class="row ">
        <div class="col-6">
          <div class="card text-center" (click)="listLoansInProcess()" style="min-height: 186px;">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo-sanad" style="width: 90px; padding:0px" src="assets/images/stats.png" alt="My Image">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 "> {{'TITLE.existing_financing' | translate}}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="card text-center" (click)="listLoanAppliesStatuses()" style="min-height: 186px;">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo-sanad" style="width: 90px;padding:0px" src="assets/images/money-up.png"
                alt="My Image">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 "> {{ 'LABELS.status_financing_app' | translate }}</label>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Application Statuses -->
  <div id="applications-statuses" class="flex" [ngClass]="{ 'vh-100': loansList.length < 8 }"
    *ngIf="!showAppliesMenu && showMyLoanStatuses && !showLoansInProcess">
    <app-screen-title [title]="'LABELS.status_financing_app'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>
    <div class="px-3">

      <!-- APPLICATION -->
      <div id="loans_exist">

        <div class="card card-loan px-3 py-4 mb-2" *ngFor="let loan of loansList; let index = index">
          <div class="fsb">
            <div class="p-2 flc inline-flex align-items-center" style="background-color: #F3F4F9;border-radius: 50%;">
              <img class="img-logo-sanad p-0" style="width: 50px" src="assets/images/money-up.png" alt="My Image">
            </div>
            <div class="d-grid">
              <label class="mb-1 color1 fw-700" *ngIf="loan.accountNumber">{{loan.accountNumber}}</label>
              <label class="mb-1 fw-500 fss op-50">{{loan.initialPaymentDate| date}} </label>
            </div>
            <button class="btn btn-status-pending btn-lg  login-btn"
              *ngIf="loan.stepPath != 'CANCELLED' && loan.statutLibelle != 'Issued' && loan.statutLibelle != 'Disburse' && loan.statutLibelle != 'issued'">{{'STATUSES.pending'
              | translate}}</button>
            <button class="btn btn-status-pending btn-lg  login-btn"
              *ngIf="loan.statutLibelle == 'Disburse'">{{'STATUSES.pending_disburse' | translate}}</button>
            <button class="btn btn-status-rejected btn-lg  login-btn"
              *ngIf="loan.stepPath === 'CANCELLED'">{{'STATUSES.cancelled' | translate}}</button>
            <button class="btn btn-status-success btn-lg  login-btn"
              *ngIf="loan.statutLibelle == 'Issued' || loan.statutLibelle == 'issued'">{{'STATUSES.accepted' |
              translate}}</button>
          </div>
        </div>
      </div>

      <!-- IF NO LOANS APPLIED -->
      <div id="no-loans-found" *ngIf="loansList.length == 0">
        <div class="mt-4 pt-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-20" style="width: 160px;" src="assets/images/money_calendar.png" alt="My Image">
        </div>
        <label class="label-info flc">{{'INFO.no_loans' | translate}}</label>
      </div>

    </div>

  </div>

  <!-- Applications In Process -->
  <div id="applications-in-process" class="pb-4" [ngClass]="{ 'vh-100': loansInProcess.length < 4 }"
    *ngIf="!showAppliesMenu && !showMyLoanStatuses && showLoansInProcess">
    <app-screen-title [title]="'TITLE.existing_financing'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>
    <div class="px-3">

      <!-- APPLICATION -->
      <div class="card card-loan mb-2" *ngFor="let loan of loansInProcess; let index = index">
        <div class="card-header fsb">
          <div class="fle mt-1" style="gap: 10px;">
            <div style="background-color: #F3F4F9;border-radius: 50%;width: 50px;"
              class=" flc inline-flex align-items-center">
              <img class=" mt-3 m-2" style="width: 30px;" src="assets/images/stats.png" alt="stats">
            </div>
            <label class="mb-1 pt-3 fw-700  color1">{{loan.accountNumber}}</label>
          </div>
          <div >
            <div style="zoom:50%" class="cpb" role="progressbar" *ngIf="loan.percentagePayment != undefined" [attr.aria-valuenow]="getPercentage(loan.percentagePayment,index)"
              aria-valuemin="0" aria-valuemax="100" [style.--value]="getPercentage(loan.percentagePayment,index)">
              <div class="grid">
                <label class="fw-700 white text-center mb-0 fsl">{{ 'LABELS.payment_status' |
                  translate }}</label>
                <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="loan.percentagePayment === 100">
                  {{ loan.percentagePayment / 100 | percent:'1.0-0' }} </span>
                <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="loan.percentagePayment != 100">
                  {{loan.percentagePayment | percent:'1.2-2'}}</span>
              </div>
            </div>

            <!-- Loader In Case Schedule Not Synchronized Yet -->
            <div class="scloader loader--style8 pb-0 mb-0" *ngIf="loan.percentagePayment == undefined">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                </rect>
              </svg>
            </div>

          </div>
        </div>
        <div class="card-body">
          <div class="fsb">
            <div class="d-grid">
              <label class="mb-1 fw-700 ">{{ 'LABELS.amount_term' | translate }} :
                <span class=" color1" *ngIf="!loan.totalRepayment">{{loan.normalPayment}} {{'CURRENCY.sar' | translate}}</span>
                <span class=" color1" *ngIf="loan.totalRepayment">{{loan.totalRepayment}} {{'CURRENCY.sar' | translate}}</span>
              </label>
              <label class="mb-1 fw-700 "> {{'LABELS.financing_amount' | translate}} : <span
                  class=" color1">{{loan.approvelAmount}} {{'CURRENCY.sar' | translate}}</span></label>
            </div>
            <div (click)="showLoanSchedule(index)" *ngIf="loan.percentagePayment != undefined">
              <i class='bx bxs-left-arrow-circle color1'  style="font-size: -webkit-xxx-large;" [ngClass]="{'rotate': lang==='en'}"></i>
            </div>
            <div *ngIf="loan.percentagePayment == undefined">
              <i class='bx bxs-left-arrow-circle colordisable'  style="font-size: -webkit-xxx-large; cursor: not-allowed;" [ngClass]="{'rotate': lang==='en'}"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- IF NO LOANS APPLIED -->
      <div id="no-loans-found" *ngIf="loansInProcess.length == 0">
        <div class="mt-4 pt-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-20" style="width: 160px;" src="assets/images/money_calendar.png"
            alt="money_calendar">
        </div>
        <label class="label-info flc">{{'INFO.no_loans' | translate}}</label>
      </div>

      <div class="text-center">

        <div class="row">
          <div class="col-6">
            <button class="btn btn-primary btn-lg mb-2 login-btn" (click)="syncSchedule()">
              {{'BUTTONS.sync_schedule' | translate}}
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-info btn-lg mb-2 login-btn" style="color:white"
              (click)="fetchListLoansAndDispatch()">
              {{'BUTTONS.refresh_schedule' | translate}}
            </button>
          </div>
        </div>


      </div>

    </div>
  </div>

</div>

<app-footer-menu
  *ngIf="this.sharedService.getUser().login != undefined &&showAppliesMenu && !showMyLoanStatuses"></app-footer-menu>
