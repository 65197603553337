

 /** Internet Banking Evaluation Constants */
export class EvalConstants {
  public static readonly COMPLETELY_HAPPY = 'COMPLETELY_HAPPY';
  public static readonly HAPPY = 'HAPPY';
  public static readonly NEUTRAL = 'NEUTRAL';
  public static readonly DISSATISFIED = 'DISSATISFIED';
  public static readonly COMPLETELY_DISSATISFIED = 'COMPLETELY_DISSATISFIED';
}

export class CalculationType {
  public static readonly FLAT = 'FLAT';
  public static readonly RATE = 'RATE';
}
