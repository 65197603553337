<div id="Notification List" class="bg-2 px-2 vh-100" style="overflow-y:auto ;">
  <app-screen-title [title]="'TITLE.notifications'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>

  <div id="no-loans-found" *ngIf="notificationList.length == 0">
    <div class="mt-4 pt-4 flc inline-flex align-items-center">
      <img class="img-logo-sanad op-20" style="width: 180px;" src="assets/images/notif_404.png" alt="My Image">
    </div>
    <label class="label-info flc">{{'INFO.no_notifications' | translate}}</label>
  </div>

  <div *ngIf="notificationList.length > 0">
    <div id="notification" class="mb-2" *ngFor="let notification of notificationList ; let index = index">
      <div class="row" (click)="markAsSeenOrUnseenNotification(notification)">
        <div class="col-2">
          <img class="ib-icons m-2 mt-2" style="width:42px !important" src="assets/images/icons/notif1.png" alt="notified">
        </div>
        <div class="col-10">
          <div class="fsb">
            <div class="d-flex" [ngClass]="{'red-dot': !notification.isSeen }">
              <label class="label-main color3 fsl"  [ngClass]="{'color6': notification.isSeen }" > {{ "NOTIFICATION_CATEGORIES."+notification.title | translate}} </label>
            </div>
            <label class="label-info m-2 fss">{{ calculateTimeDifference(notification.creactionDate) }}</label>
          </div>
          <!-- <br> -->
          <label class="black op-50 fsm" *ngIf="lang == 'en'"> {{notification.descriptionEn}} </label>
          <label class="black op-50 fsm" *ngIf="lang == 'ar'"> {{notification.descriptionAr}} </label>
        </div>
      </div>
    </div>
  </div>


</div>

<app-footer-menu></app-footer-menu>
