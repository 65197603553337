import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main-components/auth-components/login/login.component';
import { ForgetPasswordComponent } from './main-components/auth-components/forget-password/forget-password.component';
import { OtpBiometricsValidationComponent } from './main-components/auth-components/otp-biometrics-validation/otp-biometrics-validation.component';
import { HomeComponent } from './main-components/ib-components/home/home.component';
import { SimulateLoanComponent } from './main-components/ib-components/simulate-loan/simulate-loan.component';
import { SettingsComponent } from './main-components/ib-components/settings/settings.component';
import { AccountDetailsComponent } from './main-components/ib-components/settings/account-details/account-details.component';
import { PersonalInformationComponent } from './main-components/ib-components/settings/personal-information/personal-information.component';
import { EditPersonalInformationComponent } from './main-components/ib-components/settings/edit-personal-information/edit-personal-information.component';
import { EditWorkInformationComponent } from './main-components/ib-components/settings/editWorkInformation/editWorkInformation.component';
import { LoanApplicationsComponent } from './main-components/ib-components/loan-applications/loan-applications.component';
import { LoanScheduleDetailsComponent } from './main-components/ib-components/loan-applications/loan-schedule-details/loan-schedule-details.component';
import { EditIncomeDetailsComponent } from './main-components/ib-components/settings/edit-income-details/edit-income-details.component';
import { EditExpensesObligationsComponent } from './main-components/ib-components/settings/edit-expenses-obligations/edit-expenses-obligations.component';
import { ContactUsComponent } from './main-components/ib-components/settings/contact-us/contact-us.component';
import { FaqComponent } from './main-components/ib-components/settings/faq/faq.component';
import { SchedulePaymentComponent } from './main-components/ib-components/loan-applications/schedule-payment/schedule-payment.component';
import { DocumentsComponent } from './main-components/ib-components/loan-applications/documents/documents.component';
import { ScheduleServicesComponent } from './main-components/ib-components/loan-applications/schedule-services/schedule-services.component';
import { ProductListComponent } from './main-components/ib-workflow-components/product-list/product-list.component';
import { ConsentValidationComponent } from './main-components/ib-workflow-components/consent-validation/consent-validation.component';
import { AboutSanadComponent } from './main-components/ib-components/settings/about-sanad/about-sanad.component';
import { BankInformationComponent } from './main-components/ib-workflow-components/bank-information/bank-information.component';
import { NafedhComponent } from './main-components/auth-components/nafedh/nafedh.component';
import { InformationCustomerEntryComponent } from './main-components/ib-workflow-components/information-customer-entry/information-customer-entry.component';
import { RequiredDocumentsComponent } from './main-components/ib-workflow-components/required-documents/required-documents.component';
import { SummaryInitApprovalComponent } from './main-components/ib-workflow-components/summary-init-approval/summary-init-approval.component';
import { PeriodSelectionComponent } from './main-components/ib-workflow-components/period-selection/period-selection.component';
import { SignContractComponent } from './main-components/ib-workflow-components/sign-contract/sign-contract.component';
import { NewTransactionComponent } from './main-components/ib-components/loan-applications/new-transaction/new-transaction.component';
import { PaymentStatusComponent } from './main-components/ib-components/payment-status/payment-status.component';
import { ResetPasswordComponent } from './main-components/ib-components/settings/account-details/reset-password/reset-password.component';
import { LoanStatusComponent } from './main-components/ib-workflow-components/loan-status/loan-status.component';
import { BiometricsSettingsComponent } from './main-components/ib-components/settings/biometrics-settings/biometrics-settings.component';
import { ChangeLanguageComponent } from './main-components/ib-components/settings/change-language/change-language.component';
import { AdvertisementComponent } from './advertising-pages/advertisement/advertisement.component';
import { ApplicationTermsComponent } from './main-components/ib-workflow-components/application-terms/application-terms.component';
import { NotificationListComponent } from './main-components/auth-components/notification-list/notification-list.component';
import { ClaimsComponent } from './main-components/ib-components/settings/claims/claims.component';
import { ViewClaimComponent } from './main-components/ib-components/settings/view-claim/view-claim.component';
const routes: Routes = [
  /** Auth Components */
  {path: '',component : LoginComponent},
  {path:'login',component: LoginComponent},
  {path:'forgetpassword',component: ForgetPasswordComponent },
  {path:'otp-biometrics',component: OtpBiometricsValidationComponent },
  {path:'nafedh-auth',component: NafedhComponent },
  {path:'advertisement',component: AdvertisementComponent },

  /** IB Components */
  {path:'home',component: HomeComponent, data: { animation: 'home' } },
  {path:'simulate',component: SimulateLoanComponent, data: { animation: 'simulate' }  },
  {path:'loan-applications',component: LoanApplicationsComponent },
  {path:'loan-schedule-details',component: LoanScheduleDetailsComponent },
  {path:'schedule-payment',component: SchedulePaymentComponent },
  {path:'documents',component: DocumentsComponent },
  {path:'schedule-services',component : ScheduleServicesComponent},
  {path:'new-transaction',component : NewTransactionComponent},
  {path:'statut-payment',component : PaymentStatusComponent},
  {path:'loan-statut',component : LoanStatusComponent},


  /** IB WORKFLOW Components */
  {path:'application-terms',component : ApplicationTermsComponent},
  {path:'product-list',component : ProductListComponent},
  {path:'consent-validation',component : ConsentValidationComponent},
  {path:'information-entry',component : InformationCustomerEntryComponent},
  {path:'bank-information',component : BankInformationComponent},
  {path:'required-documents',component : RequiredDocumentsComponent},
  {path:'summary-initial-approval',component : SummaryInitApprovalComponent},
  {path:'period-selection',component : PeriodSelectionComponent},
  {path:'sign-contract',component : SignContractComponent},

  /** Settings Components */
  {path:'settings',component: SettingsComponent },
  {path:'account-details',component: AccountDetailsComponent },
  {path:'personal-information',component: PersonalInformationComponent },
  {path:'edit-personal-info',component: EditPersonalInformationComponent },
  {path:'edit-work-info',component: EditWorkInformationComponent },
  {path:'edit-income-details',component: EditIncomeDetailsComponent },
  {path:'edit-expenses-obligations',component: EditExpensesObligationsComponent },
  {path:'contact-us',component: ContactUsComponent },
  {path:'faq',component : FaqComponent},
  {path:'about-sanad',component : AboutSanadComponent},
  {path:'reset-password',component : ResetPasswordComponent},
  {path:'biometrics-settings',component: BiometricsSettingsComponent },
  {path:'change-language',component : ChangeLanguageComponent},
  {path:'notifications',component: NotificationListComponent },
  {path:'claims',component: ClaimsComponent },
  {path:'view-claim',component: ViewClaimComponent }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
