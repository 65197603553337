<div [dir]="getDirection()">
  <div *ngIf="!isFirstLaunch">
    <app-loader></app-loader>
    <div *ngIf="!showBlockedPaged && !isJailbroken">
      <router-outlet></router-outlet>
    </div>

    <!-- Blocked Page Due To IP Out Of Range -->
    <div *ngIf="showBlockedPaged">
      <div class="mt-4 text-center cp-lr-10">
        <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="My Image">
      </div>
      <div class="container fw-500">
        <div id="sec-1" class="section">
          <div id="ctn">
            <div class="text-ctn  white fw-500">{{'INFO.not_available' |translate }} {{'INFO.in_your_country' |
              translate}}</div>
            <div class="text-ctn  white fw-500 fsm">{{ 'Available only in Saudi Arabia' | translate }}</div>
            <img class="img-logo-sanad" style="filter: invert(1);" src="assets/images/block.png" alt="My Image">
          </div>
        </div>
      </div>
    </div>

    <!-- Blocked IF Rooted or Jailbroken -->
    <div *ngIf="isJailbroken">
      <div class="mt-4 text-center cp-lr-10">
        <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="My Image">
      </div>
      <div class="container fw-500">
        <div id="sec-1" class="section">
          <div id="ctn">
            <div class="text-ctn  white fw-500"> {{ 'INFO.jailbreak_detected' | translate}} </div>
            <div class="text-ctn  white fw-500 fsm">{{ 'INFO.jailbreak_block' | translate }}</div>
            <img class="img-logo-sanad" style="filter: invert(1);" src="assets/images/block.png" alt="My Image">
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="isFirstLaunch">
    <!-- Display your information here -->
    <app-advertisement></app-advertisement>
  </div>
</div>
