import { CustomerEntity } from "./customer.entity";
import { LoanProcessInstanceEntity } from "./loanProcessInstance.entity";
import { ProductEntity } from "./product.entity copy";
import { ScheduleEntity } from "./schedule.entity";
import { UserDefinedFieldsLinksEntity } from "./userDefinedFieldsLinks.entity";

export class LoanEntityIB {
  loanId: number;
  applyAmountTotal: number;
  approvelAmount: number;
  applyDate: string;
  cuLoanReFinanceReason: string;
  currencyId: string;
  gracePeriod: string;
  industryCode: number;
  industryCodeDescription: string;
  issueDate: Date;
  loanReason: string;
  loanSourceOffunds: string;
  portfolioId: string;
  productId: number;
  processInstanceId: string;
  idLoanExtern: string;
  statut: string;
  idAccountExtern: string;
  accountNumberExtern: string;
  accountNumber: string;
  creationDate: string;
  termPeriodNum: number;
  paymentPeriod: string;
  paymentFreq: string;
  issueFeeAmount: string;
  productCode: string;
  productDescription: string;
  productRate: number;
  customerId: number;
  loanReasonId: number;
  loanReasonCode: string;
  loanReasonDescription: string;
  portfolioCode: string;
  portfolioDescription: string;
  currencySymbol: string;
  currencyDecimalPlaces: string;
  customerName: string;
  owner: string;
  statutLibelle: string;
  statutWorkflow: number;
  etapeWorkflow: number;
  workflowNextAction: string;
  pourcentage: string;
  ihmRoot: string;
  note: string;
  dateLastUpdate: Date;
  contactDateCustomerDecision: Date;
  commentsCustomerDecision: string;
  confirm: boolean;
  normalPayment: number;
  codeExternMotifRejet: number;
  category: string;
  loanCalculationMode: number;
  listMissingData: string[];
  customerDTO: CustomerEntity;
  customerType: string;
  parentId: number;
  processName: string;
  assignCustomer: boolean;
  findGuarantor: boolean;
  loanInstancesDtos: LoanProcessInstanceEntity[];
  initialPaymentDate: Date;
  termPeriodID: number;
  periodsDeferred: number;
  periodsDeferredType: number;
  sourceOfFundsID: number;
  ignoreOddDays: boolean;
  ownerName: string;
  districtCodeId: number;
  interestFreq: number;
  intPayPeriodNum: number;
  userDefinedFieldsLinksDTOs: UserDefinedFieldsLinksEntity[];
  apr: number;
  effectiveIntRate: number;
  productDTO: ProductEntity;
  idIbLoan: number;
  branchID: number;
  branchName: String;
  branchDescription : String;
  loanSchedule : ScheduleEntity[];
  stepPath : string;
  otherInformations : string;
  bankInformation : string;
  actionToAcm : string;
  workflowCompleted : boolean;
  maxInstallment : number;
  totalInterest : number ;
  notifyUser : boolean;
  dateInsertion : Date;
  enabled : Boolean;
  acmVersion : number;
  updatedBy : string;
  insertBy : string;

  /** Front =>  Valeurs de consommation */
  percentagePayment : number;
  totalRepayment : number;
  constructor() {}

}
