import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationService } from 'src/app/ib-services/evaluation.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { UserEvaluation } from 'src/app/shared/Entities/UserEvaluation.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { EvalConstants } from 'src/app/shared/mb-constants/ib_constants';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('iconAnimation', [
      state('clicked', style({
        transform: 'scale(1.2)', // You can adjust the scale or add other styles
      })),
      transition('* => clicked', [
        animate('0.2s'),
      ]),
    ]),
  ],
})

export class PopupComponent {
  @Input() source: string;
  @Input() number: string;

  public status : string;
  public evalForm : FormGroup;
  public customer : CustomerEntity;
  public loanEval : LoanEntityIB;

  public iconState: string[] = ['','','','',''];

  constructor(private translate : TranslateService, private evalutationService : EvaluationService, private formBuilder : FormBuilder, private sharedService : SharedService, private router : Router) { }

  ngOnInit() {
    this.loanEval =  this.sharedService.getLoanForEval();
    if (this.source == "EVALUATION") this.createEvalForm();
    this.customer = this.sharedService.getCustomer();
  }

  ratingChecker(satisfactionStatus : string){
    switch (satisfactionStatus){
      case EvalConstants.COMPLETELY_HAPPY:
        this.status = this.translate.instant('EVALUATION.completely_satisfied');
        this.evalForm.controls['evalStatus'].setValue(this.status);
        this.iconState = ['','','','',''];
        this.iconState[0] = 'clicked';
        break;
      case EvalConstants.HAPPY:
        this.status = this.translate.instant('EVALUATION.satisfied');
        this.evalForm.controls['evalStatus'].setValue(this.status);
        this.iconState = ['','','','',''];
        this.iconState[1] = 'clicked';
        break;
      case EvalConstants.NEUTRAL:
        this.status = this.translate.instant('EVALUATION.neutral');
        this.evalForm.controls['evalStatus'].setValue(this.status);
        this.iconState = ['','','','',''];
        this.iconState[2] = 'clicked';
        break;
      case EvalConstants.DISSATISFIED:
        this.status = this.translate.instant('EVALUATION.dissatisfied');
        this.evalForm.controls['evalStatus'].setValue(this.status);
        this.iconState = ['','','','',''];
        this.iconState[3] = 'clicked';
        break;
      case EvalConstants.COMPLETELY_DISSATISFIED:
        this.status = this.translate.instant('EVALUATION.not_satisfied');
        this.evalForm.controls['evalStatus'].setValue(this.status);
        this.iconState = ['','','','',''];
        this.iconState[4] = 'clicked';
        break;
    }
  }

  createEvalForm(){
    this.evalForm = this.formBuilder.group({
      evalStatus : ['', Validators.required],
      description : ['', Validators.required],
    });
  }

  addEvaluation(){
    if (this.evalForm.valid){
      let userEvaluation = new UserEvaluation();
      userEvaluation.creactionDate = new Date();
      userEvaluation.description = this.evalForm.controls['description'].value;
      userEvaluation.evalStatus = this.evalForm.controls['evalStatus'].value;
      userEvaluation.customerIdentity = this.customer.identity;
      userEvaluation.idCustomer = this.customer.id;
      userEvaluation.idloan = this.loanEval?.idIbLoan ?? null;
      this.evalutationService.addUserEvaluation(userEvaluation).subscribe(res => {
        this.sharedService.showSuccess(this.translate.instant('INFO.eval_success'));
        this.redirect();
      })
    }else {
      this.sharedService.showError(this.translate.instant('ERR.eval_failed'));
    }
  }

  redirect(){
    if (location.pathname === '/' + PathConstants.PATH_HOME){
      window.location.reload();
    }else {
      this.router.navigate(['/' + PathConstants.PATH_HOME]);
    }
  }

}
