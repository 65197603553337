import { Direction, Directionality } from '@angular/cdk/bidi';
import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from './ib-services/authentification.service';
import { SharedService } from './ib-services/shared.service';
import { CreditService } from './ib-services/credit.service';
import { UserEntity } from './shared/Entities/user.entity';
import { ProductEntity } from './shared/Entities/product.entity copy';
import { ParametrageService } from './ib-services/parametrage.service';
import { debounceTime } from 'rxjs';
import { routeAnimations } from './shared/mb-constants/routeAnimations';
import { IpService } from './ib-services/ip.service';
import { WebsocketService } from './ib-services/Websocket.service';
import { Geolocation } from '@capacitor/geolocation';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';
import { CapacitorJailbreakRootDetection } from '@swernimont/capacitor-jailbreak-root-detection';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { environment } from 'src/environments/environment';
import { PathConstants } from './shared/mb-constants/path-constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceFocalRequestDTO } from './shared/Entities/DeviceFocalRequest.entity';
import { Device } from '@capacitor/device';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {

  public title = 'sanad-finance-template';
  static direction: Direction;
  public isMobile: Boolean;
  public user: UserEntity;
  public usernameAbacusPayment: string;
  private logTimer: number = 300000;
  public showBlockedPaged: boolean = false;
  public myIp: string;
  public isFirstLaunch: boolean;
  public isJailbroken: boolean = false;
  public location: any;
  public dataLocation: any;
  public ipAddress: any;

  constructor(
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
    public httpClient: HttpClient,
    public router: Router,
    public dir: Directionality,
    private ngZone: NgZone,
    private authService: AuthentificationService,
    public sharedService: SharedService,
    public creditService: CreditService,
    public parametrageService: ParametrageService,
    public ipService: IpService,
    public webSocketService: WebsocketService,
    private http: HTTP,
    private platform: Platform,
    private zone: NgZone
  ) {
    translate.addLangs(['en', 'ar']);
    const lang = localStorage.getItem('lang');
    if (lang === null || lang === 'ar') {
      this.langAr();
    } else if (lang === 'en') {
      this.langEn();
    }

    this.initializeApp();
  }

  async ngOnInit() {
    this.fetchIpAddress();
    this.initializeStatusBar();
    this.retrieveDeviceDetails();
    this.hasStoredCredentials();
    this.platform.ready().then(() => {
      this.http
        .setServerTrustMode('pinned')
        .then((res) => {
          console.log('Congratulations, you have setted up SSL Pinning.', res);
        })
        .catch(() => {
          console.log('SSL Pinning Failed.');
        });
    });
    this.checkDeviceStatus();
    this.isJailbroken = false;
    this.getCurrentLocation();

    if (this.isJailbroken) {
      /** Jailbreaked */
      console.log('IOS Jailbreaked');
    } else {
      /** Authorized Since its not jailbreaked */
      this.isFirstLaunch = await this.sharedService.isFirstLaunch();
      if (this.isFirstLaunch) {
        await this.sharedService.setNotFirstLaunch();
      }
      this.sharedService.setLang(localStorage.getItem('lang'));
      this.detectDevice();
      this.detectUrl();
      await this.connect().then((v) => {});
      this.user = this.sharedService.getUser();
      /** SelfRefresh in case location is home  */
      if (this.sharedService.getUser().login === undefined) {
        this.router.navigate(['']);
      } else {
        let product = new ProductEntity();
        this.parametrageService.getProducts(product).subscribe((res) => {
          this.sharedService.setProduct(res[0]);
        });
        this.router.navigate(['/' + PathConstants.PATH_HOME]);
      }

      /** Delog After Five minutes of session activeness for security reason (Requested by the Central Bank) */
      if (this.sharedService.getUser()) {
        this.authService.userActivity$
          .pipe(debounceTime(1000))
          .subscribe(() => this.resetInactivityTimeout());
      }
    }
  }

  async fetchIpAddress() {
    try {
      const response = await this.httpClient
        .get('https://api.ipify.org', { responseType: 'text' })
        .toPromise();
      let ipAddress = response;
      this.sharedService.setIpAddress(ipAddress);
    } catch (error) {
      console.error('Failed to fetch IP address:', error);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  ipChecker() {
    this.getCurrentLocation();
  }

  async checkDeviceStatus() {
    try {
      const jailbreakRootResult =
        await CapacitorJailbreakRootDetection.isJailbrokenOrRooted();
      console.log('Is Jailbroken or Rooted:', jailbreakRootResult.result);

      const simulatorResult =
        await CapacitorJailbreakRootDetection.isSimulator();
      console.log('Is Simulator:', simulatorResult.result);

      const debuggedModeResult =
        await CapacitorJailbreakRootDetection.isDebuggedMode();
      console.log('Is Debugged Mode:', debuggedModeResult.result);

      if (jailbreakRootResult.result) {
        CapacitorJailbreakRootDetection.exitApp();
      }
    } catch (error) {
      console.error('Error checking device status:', error);
    }
  }

  async getCurrentLocation() {
    try {
      let coordinates = await Geolocation.getCurrentPosition();
      let permission = await Geolocation.checkPermissions();
      if (permission.location == 'granted') {
        this.location = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };

        let locationString =
          this.location.latitude.toString() +
          ',' +
          this.location.longitude.toString();
        this.sharedService.setLocation(locationString);

        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.location.latitude}&lon=${this.location.longitude}`
        );
        const data = await response.json();

        console.log('GeoLocation :', data);

        if (data && data.display_name) {
          this.dataLocation = data;
          if (
            this.dataLocation.address.country_code != 'sa' &&
            this.dataLocation.address.country_code != 'SA'
          ) {
            this.showBlockedPaged = true;
            this.dataLocation = null;
            await Geolocation.clearWatch(data.id);
          }
        } else {
          console.log('Location name not available');
        }
      } else {
        console.log('GPS Geolocation Disabled!');
      }
    } catch (error) {
      console.error('Error getting location', error);
    }
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'sanaduat.sanadfinance.com';
        // const domain = 'sanad.finance.com'
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  async getDeviceId() {
    return (await Device.getId()).identifier;
  }

  async retrieveDeviceDetails() {
    let device = this.deviceService.getDeviceInfo();
    let deviceFocal = new DeviceFocalRequestDTO();
    deviceFocal.browser = device.browser;
    deviceFocal.os = device.os;
    deviceFocal.type = device.deviceType;
    deviceFocal.reference = (await this.getDeviceId()).toString();
    this.sharedService.setDeviceInfo(deviceFocal);
  }

  private resetInactivityTimeout() {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.authService.inactivityTimeout);
      this.authService.inactivityTimeout = setTimeout(() => {
        this.ngZone.run(() => {
          if (this.authService.getIsLoggedIn()) {
            this.authService.logout();
          }
        });
      }, this.logTimer);
    });
  }

  @HostListener('window:mousemove') onUserActivity() {
    this.authService.onUserActivity();
    this.resetInactivityTimeout();
  }

  async connect() {
    if (localStorage.getItem('currentUser') != null) {
      if (localStorage.getItem('type') === 'user') {
        await this.authService
          .curentUser()
          .toPromise()
          .then((data) => {
            this.sharedService.setUser(data);
            this.authService.setIsLoggedIn(true);
            this.router.navigate['/' + PathConstants.PATH_HOME];
          });
      }
    }
  }

  getDirection() {
    return AppComponent.direction;
  }

  langEn() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
    this.sharedService.setLang('en');
    AppComponent.direction = 'ltr';
  }

  langAr() {
    this.translate.use('ar');
    localStorage.setItem('lang', 'ar');
    this.sharedService.setLang('ar');
    AppComponent.direction = 'rtl';
  }

  detectDevice() {
    this.isMobile = window.innerWidth < 768;
    this.sharedService.setIsMobile(this.isMobile);
  }

  detectUrl() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        const shouldDisplayHeader = url === '/login' ? false : true;
        this.sharedService.setshouldDisplayHeader(shouldDisplayHeader);
      }
    });
  }

  getScreenResolution(): { width: number; height: number } {
    return {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
    };
  }

  /** Biometrics Methods */
  async hasStoredCredentials() {
    try {
      const result = await NativeBiometric.getCredentials({
        server: environment.oauthUrl,
      });
      this.sharedService.setUserHadBiometricCredentials(true);
    } catch (error) {
      this.sharedService.setUserHadBiometricCredentials(false);
    }
  }

  initializeStatusBar() {
    StatusBar.show().catch(err => console.log('Error showing status bar:', err));
    StatusBar.setStyle({ style: Style.Light }).catch(err => console.log('Error setting status bar style:', err));
    StatusBar.setBackgroundColor({ color: '#ffffff' }).catch(err => console.log('Error setting background color:', err));
  }

}
