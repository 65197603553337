<div class="bg-2 vw-100" style="min-height: 100vh;">

  <!-- Schedule Services Menu -->
  <div id="menu-applications" *ngIf="showServicesMenu && !showEarlyPayment && !showRefinance">
    <app-screen-title [title]="'BUTTONS.services'" [color]="'color1'"
      [backTo]="'/loan-schedule-details'"></app-screen-title>

    <div class="px-4 mt-4">
      <div class="row ">
        <div class="col-6">
          <div class="card text-center" (click)="earlyPayment()">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo-sanad" style="width: 90px;padding:0px" src="assets/images/money-up.png"
                alt="money-up">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 fsm"> {{'LABELS.early_payment' | translate}} </label>
          </div>
        </div>
        <div class="col-6">
          <div class="card text-center" (click)="refinance()">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo-sanad" style="width: 90px;padding:0px" src="assets/images/stats.png" alt="stats">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 fsm">{{ 'LABELS.refinancing' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Early Payment Term Period -->
  <div id="early-payment" *ngIf="!showServicesMenu && showEarlyPayment && !showRefinance">
    <app-screen-title [title]="'LABELS.early_payment'" [color]="'color1'"
      [backTo]="'/loan-schedule-details'"></app-screen-title>
    <div class="px-3 mt-4">
      <div class="card cu-card text-center p-2 pt-3 mb-3">

        <!-- Curved Progress Bar Percentage -->
        <div class="flc pb-4">
          <div class="cpb" role="progressbar" [attr.aria-valuenow]="getPercentage()" aria-valuemin="0"
            aria-valuemax="100" [style.--value]="getPercentage()">
            <div class="grid">
              <label class="fw-700 color3 text-center mb-0 fsm" style="margin-bottom:unset">{{ 'LABELS.payment_status' |
                translate }}</label>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus === 100"> {{paymentStatus }}% </span>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus != 100"> {{paymentStatus |
                percent:'1.2-2'}}</span>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus == undefined"> 0%</span>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2 " src="assets/images/icons/money_case_icon.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label ms-2 fw-650 fss">{{ 'LABELS.remaining_amount' | translate }}</label>
                <label class="form-label ms-2 fw-650 fss text-primary">{{remainingAmount}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons pt-3 m-2 " src="assets/images/icons/money_icon.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label ms-2 fw-650 fss">{{ 'LABELS.payment_done' | translate }}</label>
                <label class="form-label ms-2 fw-650 fss text-primary"> {{donePayment}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2 " src="assets/images/icons/check2.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label fw-650 fss">{{ 'LABELS.premium_paid' | translate }}</label>
                <label class="form-label fw-650 text-primary fss"> {{termPaid}} / {{loan.termPeriodNum}} </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2" src="assets/images/icons/check1.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label fss fw-650">{{ 'LABELS.total_amount' | translate }}</label>
                <label class="form-label fw-650 fss text-primary">{{loan.approvelAmount}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card sticky-card flc p-4">
            <label class="mb-1 color1 fw-650"> {{ 'LABELS.Last_installment' | translate }}</label>
            <label class="mb-1 fw-650"> {{loan.normalPayment}} {{ 'CURRENCY.sar' | translate }}</label>
            <label class="fss op-50"> {{lastRepaymentDate}} </label>
          </div>
        </div>
        <div class="col-6">
          <div class="card sticky-card flc p-4">
            <label class="mb-1 color1 fw-650"> {{ 'LABELS.next_installment' | translate
              }}</label>
            <label class="mb-1 fw-650">{{loan.normalPayment}} {{ 'CURRENCY.sar' | translate }}</label>
            <label class="op-50 fw-500 fss"> {{nextRepaymentDate}} </label>
          </div>
        </div>
      </div>


      <div class="text-center pt-4 fixed-bottom px-3">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="payer()">
          {{'BUTTONS.request' | translate}}
        </button>
      </div>

    </div>
  </div>

  <!-- Refinance Term Period -->
  <div id="refinance" class="px-2" *ngIf="!showServicesMenu && !showEarlyPayment && showRefinance">
    <app-screen-title [title]="'TITLE.refinancing'" [color]="'color1'"
      [backTo]="'/loan-schedule-details'"></app-screen-title>
    <p class="text-center"> Screen not available !</p>
  </div>

</div>
